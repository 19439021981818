import React from "react";
import {
  Box,
  TextField,
  IconButton,
  CircularProgress,
  Divider,
  Chip,
  Drawer,
  Typography,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import {
  PlayArrow as PlayArrowIcon,
  Search as SearchIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import { MathJax } from "better-react-mathjax";

const RightSidebar = ({
  isDrawerOpen,
  toggleDrawer,
  isProcessing,
  searchQuery,
  handleSearchQueryChange,
  requestGeneratePrompt,
  prompts,
  selectedTopic,
  setSelectedTopic,
  selectedAnswers,
  handleAnswerSelect,
  handleDeeperPromptGeneration,
  handleAddToQuiz,
  defaultOptions,
}) => {
  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={toggleDrawer(false)}
      PaperProps={{
        sx: {
          width: "350px",
        },
      }}
    >
      <Box sx={{ padding: 2, mt: 1, width: "100%" }}>
        {/* Search Bar */}
        <Box sx={{ width: "100%", mb: 2 }}>
          <TextField
            label="Enter Topic"
            margin="normal"
            size="large"
            fullWidth
            value={searchQuery}
            onChange={handleSearchQueryChange}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => requestGeneratePrompt(searchQuery)}
                  disabled={isProcessing}
                >
                  {isProcessing ? (
                    <CircularProgress size={24} />
                  ) : (
                    <PlayArrowIcon />
                  )}
                </IconButton>
              ),
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && !isProcessing) {
                requestGeneratePrompt(searchQuery);
              }
            }}
            autoComplete="off"
          />
        </Box>

        {/* Topic Chips */}
        <Box sx={{ width: "100%", mb: 2 }}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {prompts.map((prompt, index) => (
              <Chip
                key={index}
                label={prompt.topic}
                onClick={() =>
                  setSelectedTopic(
                    selectedTopic === prompt.topic ? null : prompt.topic,
                  )
                }
                color={selectedTopic === prompt.topic ? "primary" : "default"}
                variant={selectedTopic === prompt.topic ? "filled" : "outlined"}
              />
            ))}
          </Box>
        </Box>

        {/* Content */}
        {isProcessing ? (
          <Lottie options={defaultOptions} height={200} width={200} />
        ) : (
          prompts.length > 0 && (
            <>
              {prompts
                .filter(
                  (prompt) => !selectedTopic || prompt.topic === selectedTopic,
                )
                .map((prompt, index) => (
                  <Box key={index} sx={{ mb: 4, width: "100%" }}>
                    <Typography variant="h7" sx={{ mb: 2 }}>
                      {prompt.topic}
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      {/* Subtopic Cards */}
                      {prompt.suggestions.map((suggestion, suggestionIndex) => {
                        const selectedAnswer =
                          selectedAnswers[`${index}-${suggestionIndex}`];
                        const isCorrect =
                          selectedAnswer === suggestion.sampleMCQ.correctAnswer;

                        return (
                          <motion.div
                            key={`${index}-${suggestionIndex}`}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                              duration: 0.5,
                              delay: suggestionIndex * 0.1,
                            }}
                          >
                            <Card
                              variant="outlined"
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                "&:hover": {
                                  boxShadow: 6,
                                  transform: "scale(1.02)",
                                  transformOrigin: "center center",
                                  transition: "transform 0.2s ease-in-out",
                                },
                              }}
                            >
                              <CardContent
                                sx={{
                                  flexGrow: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    mb: 2,
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      flexGrow: 1,
                                      textAlign: "center",
                                    }}
                                  >
                                    <MathJax dynamic>
                                      {suggestion.suggestion}
                                    </MathJax>
                                  </Typography>
                                  <IconButton
                                    onClick={() =>
                                      handleDeeperPromptGeneration(
                                        `${prompt.topic} - ${suggestion.suggestion}`,
                                      )
                                    }
                                    size="small"
                                  >
                                    <SearchIcon />
                                  </IconButton>
                                </Box>

                                {suggestion.sampleMCQ && (
                                  <Box sx={{ mb: 2, flexGrow: 1 }}>
                                    <Typography variant="body1" sx={{ mb: 1 }}>
                                      <MathJax dynamic>
                                        {suggestion.sampleMCQ.question}
                                      </MathJax>
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 1,
                                      }}
                                    >
                                      {suggestion.sampleMCQ.options.map(
                                        (option, i) => {
                                          const isSelected =
                                            selectedAnswer === option;
                                          const showCorrect =
                                            selectedAnswer &&
                                            option ===
                                              suggestion.sampleMCQ
                                                .correctAnswer;
                                          const showIncorrect =
                                            isSelected && !isCorrect;

                                          return (
                                            <Typography
                                              key={i}
                                              sx={{
                                                border: 2,
                                                borderColor: showCorrect
                                                  ? "success.main"
                                                  : showIncorrect
                                                    ? "error.main"
                                                    : "inherit",
                                                color: showCorrect
                                                  ? "success.main"
                                                  : showIncorrect
                                                    ? "error.main"
                                                    : "inherit",
                                                borderWidth: 2,
                                                padding: "8px 16px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                cursor: "pointer",
                                                borderRadius: 1,
                                                transition:
                                                  "background-color 0.2s ease",
                                                "&:hover": {
                                                  backgroundColor: showCorrect
                                                    ? "success.light"
                                                    : showIncorrect
                                                      ? "error.light"
                                                      : "action.hover",
                                                },
                                              }}
                                              onClick={() =>
                                                handleAnswerSelect(
                                                  `${index}-${suggestionIndex}`,
                                                  option,
                                                )
                                              }
                                            >
                                              <MathJax dynamic>
                                                {option}
                                              </MathJax>
                                              {showCorrect && <CheckIcon />}
                                            </Typography>
                                          );
                                        },
                                      )}
                                    </Box>
                                    {selectedAnswer && (
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{ mt: 1 }}
                                      >
                                        Correct Answer:{" "}
                                        <strong>
                                          <MathJax dynamic>
                                            {suggestion.sampleMCQ.correctAnswer}
                                          </MathJax>
                                        </strong>
                                        Explanation:{" "}
                                        <strong>
                                          <MathJax dynamic>
                                            {suggestion.sampleMCQ.explanation}
                                          </MathJax>
                                        </strong>
                                      </Typography>
                                    )}
                                  </Box>
                                )}

                                <Button
                                  onClick={() => handleAddToQuiz(suggestion)}
                                  sx={{
                                    width: "100%",
                                    mt: "auto",
                                    padding: "4px 8px",
                                    fontSize: "0.875rem",
                                    textTransform: "none",
                                  }}
                                >
                                  Add to Quiz
                                </Button>
                              </CardContent>
                            </Card>
                          </motion.div>
                        );
                      })}
                    </Box>
                  </Box>
                ))}
            </>
          )
        )}
      </Box>
    </Drawer>
  );
};

export default RightSidebar;
