import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  LinearProgress,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SessionDetails = ({ loadingSession, selectedSession }) => {
  const [createdAt, setCreatedAt] = useState(null);
  const [title, setTitle] = useState(null);

  useEffect(() => {
    if (selectedSession) {
      const firstSession = selectedSession.sessionDetails[0];
      if (firstSession) {
        setCreatedAt(firstSession.created_at);
        setTitle(firstSession.quizTitle); // title should come from selectedSession
      }
    }
  }, [selectedSession]);

  if (loadingSession) {
    return <CircularProgress />;
  }

  if (!selectedSession) {
    return null;
  }

  const { sessionDetails, responses } = selectedSession;

  const calculateOptionCounts = (sessionDetails, responses) => {
    return sessionDetails.map((session) => {
      const {
        data: { options },
        contentId,
      } = session;

      const optionCounts = options.reduce((counts, option) => {
        counts[option] = 0;
        return counts;
      }, {});

      responses.forEach(({ response, contentId: responseContentId }) => {
        if (
          responseContentId === contentId &&
          optionCounts.hasOwnProperty(response)
        ) {
          optionCounts[response]++;
        }
      });

      return {
        ...session,
        optionCounts,
      };
    });
  };

  const calculateParticipantPerformance = () => {
    const performance = {};

    responses.forEach(
      ({
        participantId,
        participantName,
        participantAvatar,
        response,
        contentId,
      }) => {
        if (!performance[participantId]) {
          performance[participantId] = {
            participantName,
            participantAvatar,
            correct: 0,
            incorrect: 0,
            total: 0,
            responses: [],
          };
        }

        const question = sessionDetails.find(
          (session) => session.contentId === contentId,
        );

        if (question) {
          performance[participantId].total++;
          const isCorrect = response === question.data.correctAnswer;

          performance[participantId].responses.push({
            question: question.data.question,
            response,
            isCorrect,
            correctAnswer: question.data.correctAnswer,
          });

          if (isCorrect) {
            performance[participantId].correct++;
          } else {
            performance[participantId].incorrect++;
          }
        }
      },
    );

    return Object.values(performance);
  };

  const sessionsWithOptionCounts = calculateOptionCounts(
    sessionDetails,
    responses,
  );

  const getOptionProgressBarColor = (isCorrect) => {
    return isCorrect
      ? "rgba(76, 175, 80, 0.8)" // Green for correct
      : "rgba(244, 67, 54, 0.8)"; // Red for incorrect
  };

  const formatToLocalTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const renderAggregatedSummary = () => {
    const totalQuestions = sessionDetails.length;
    const totalParticipants = new Set(responses.map((r) => r.participantId))
      .size;

    return (
      <Box
        sx={{
          padding: 2,
          marginBottom: 2,
          border: "1px solid #ddd",
          borderRadius: 2,
        }}
      >
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1">
          <strong>Hosted At:</strong> {formatToLocalTime(createdAt)}
        </Typography>
        <Typography variant="body1">
          <strong>Total Questions:</strong> {totalQuestions}
        </Typography>
        <Typography variant="body1">
          <strong>Total Participants:</strong> {totalParticipants}
        </Typography>
      </Box>
    );
  };

  const renderQuestions = () => {
    return (
      <Box sx={{ padding: 4, borderRadius: 2 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", color: "#333" }}
        >
          Session Details
        </Typography>

        {sessionsWithOptionCounts.map((session, index) => {
          const totalResponses = Object.values(session.optionCounts).reduce(
            (a, b) => a + b,
            0,
          );

          return (
            <Box
              key={index}
              sx={{
                marginBottom: 5,
                padding: 3,
                border: "1px solid #ddd",
                borderRadius: 2, // Updated to 2
                // Removed backgroundColor and boxShadow
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "#444" }}
              >{`Question ${index + 1}`}</Typography>
              <Typography variant="body1" sx={{ marginY: 2, color: "#555" }}>
                {session.data.question}
              </Typography>

              {session.data.options.map((option, optionIndex) => {
                const count = session.optionCounts[option];
                const percentage = totalResponses
                  ? (count / totalResponses) * 100
                  : 0; // Handle division by zero
                const isCorrect = option === session.data.correctAnswer;

                return (
                  <Box
                    key={optionIndex}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 2,
                      gap: 2, // Adds space between elements
                      flexWrap: "nowrap", // Prevent wrapping onto the next line
                    }}
                  >
                    <Typography
                      sx={{
                        marginRight: 2,
                        flexBasis: "25%", // Increase space for the option text
                        fontWeight: "bold",
                        wordBreak: "break-word", // Spread long text properly
                        color: isCorrect ? "#4caf50" : "#333", // Highlight correct answer
                      }}
                      variant="body2"
                    >
                      {option}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={percentage}
                      sx={{
                        flexGrow: 1,
                        height: 10,
                        borderRadius: 5,
                        marginRight: 2,
                        backgroundColor: "rgba(211, 211, 211, 0.3)", // Light grey background
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: getOptionProgressBarColor(isCorrect),
                        },
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{ minWidth: "10%", color: "#666" }}
                    >
                      {`${percentage.toFixed(1)}%`}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ minWidth: "10%", color: "#666" }}
                    >
                      {`${count} responses`}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </Box>
    );
  };

  const renderParticipants = () => {
    const participantPerformance = calculateParticipantPerformance();

    return (
      <Box sx={{ padding: 4, borderRadius: 3 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", color: "#333", marginBottom: 3 }}
        >
          Participants and Scores
        </Typography>
        {participantPerformance.map((participant, idx) => (
          <Accordion
            key={idx}
            sx={{
              maxWidth: "100%",
              marginBottom: 3,
              border: "1px solid #ddd",
              borderRadius: 2,
              // Removed backgroundColor and boxShadow
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`participant-content-${idx}`}
              id={`participant-header-${idx}`}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center", // Center-align items
                padding: 2,
                borderBottom: "1px solid #ddd",
              }}
            >
              <Avatar
                src={participant.participantAvatar}
                alt={participant.participantName}
                sx={{ marginRight: 2, width: 40, height: 40 }}
              />
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", textAlign: "center" }} // Ensure the text is centered
              >
                {participant.participantName}
              </Typography>
              <Box
                sx={{
                  marginLeft: "auto",
                  display: "flex",
                  justifyContent: "center", // Center-align score boxes
                  gap: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", color: "#4caf50" }}
                >
                  {`Correct: ${participant.correct}`}
                </Typography>
                <Typography variant="body2" sx={{ color: "#f44336" }}>
                  {`Incorrect: ${participant.incorrect}`}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", color: "#333", marginRight: 2 }}
                >
                  {`Total: ${participant.total}`}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 3 }}>
              <Box sx={{ width: "100%" }}>
                {participant.responses.map((response, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      marginBottom: 2,
                      padding: 2,
                      borderRadius: 2,
                      border: "1px solid #ddd",
                      // Removed backgroundColor and boxShadow
                    }}
                  >
                    <Typography variant="body2" sx={{ marginBottom: 1 }}>
                      <strong>{`Question: `}</strong>
                      {response.question}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: response.isCorrect ? "#4caf50" : "#f44336",
                        marginBottom: 1,
                      }}
                    >
                      <strong>{`Your Response: `}</strong>
                      {response.response}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#333" }}>
                      <strong>{`Correct Answer: `}</strong>
                      {response.correctAnswer}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

  return (
    <Box sx={{ padding: 4 }}>
      {renderAggregatedSummary()}
      {renderQuestions()}
      {renderParticipants()}
    </Box>
  );
};

export default SessionDetails;
