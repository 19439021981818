import React from 'react';
import { Card, CardContent, Typography, TextField, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const GenerateMCQ = ({ additionalPrompt, setAdditionalPrompt, handleAddNewQuestion }) => {
  return (
    <Card sx={{ marginBottom: 2, width: '100%' }}>
      <CardContent>
        <Typography variant="h6">Generate MCQ</Typography>
        <TextField
          label="Enter Prompt Here"
          value={additionalPrompt}
          onChange={(event) => setAdditionalPrompt(event.target.value)}
          fullWidth
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddNewQuestion}
          disabled={!additionalPrompt.trim()}
        >
          Add Question
        </Button>
      </CardContent>
    </Card>
  );
};

export default GenerateMCQ;
