import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  CircularProgress,
  Card,
  CardContent,
  Button,
  List,
  Skeleton,
  Switch,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from "@mui/material";
import {
  PlayArrow as PlayArrowIcon,
  Check as CheckIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  Shuffle as ShuffleIcon,
} from "@mui/icons-material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import correctAnimation from "./animations/loading.json";
import QuizSyncText from "./QuizSyncText";
import topics from "./Topics.js";

import GenerateMCQ from "./GenerateMCQ";
import RightSidebar from "./RightSidebar";

const Create = ({
  socket,
  isProcessing,
  setIsProcessing,
  prompts,
  setPrompts,
  participantId,
  additionalPrompt = "",
  setAdditionalPrompt,
  isAddingQuestion,
  setIsAddingQuestion,
  editableQuestions = [],
  setEditableQuestions,
  quizId = "",
  setQuizId,
  title = "",
  setTitle,
}) => {
  // State variables
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [editMode, setEditMode] = useState(true);
  const [isGeneratingTitle, setIsGeneratingTitle] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [isStartingSession, setIsStartingSession] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // Functions for quiz management
  const handleDeeperPromptGeneration = async (suggestion) => {
    setPrompts([]);
    setIsProcessing(true);
    try {
      socket.emit("generatePrompts", { topic: suggestion });
    } catch (error) {
      console.error("Error occurred while generating prompts:", error);
      setIsProcessing(false);
    }
  };

  const saveQuiz = useCallback(() => {
    if (!quizId) {
      console.error("Quiz ID is not set. Cannot save quiz.");
      return;
    }

    const formattedQuestions = editableQuestions.map((question, index) => ({
      order: index,
      contentId: question.contentId,
      type: question.type,
      data: {
        question: question.question,
        options: question.options,
        correctAnswer: question.correctAnswer,
        explanation: question.explanation,
      },
    }));
    socket.emit("saveQuiz", {
      quizId: quizId,
      questions: formattedQuestions,
      title: title,
      topic: searchQuery,
      participantId: participantId,
    });
  }, [socket, quizId, editableQuestions, title, searchQuery, participantId]);
  // Listener for quizIdGenerated
  useEffect(() => {
    const handleQuizIdGenerated = (data) => {
      setQuizId(data.quizId);
      console.log("Quiz ID received:", data.quizId);
      saveQuiz(); // Save the quiz now that we have the quizId
    };
    socket.on("quizIdGenerated", handleQuizIdGenerated);
    return () => {
      socket.off("quizIdGenerated", handleQuizIdGenerated);
    };
  }, [socket, saveQuiz, setQuizId]);

  useEffect(() => {
    if (editableQuestions.length > 0) {
      if (!quizId) {
        socket.emit("generateQuizId");
      } else {
        saveQuiz();
      }
    }
  }, [socket, editableQuestions, quizId, saveQuiz]);

  const requestAddQuestion = useCallback(() => {
    setIsAddingQuestion(true);
    socket.emit("generateSingleQuestion", {
      quizId,
      currentQuestions: editableQuestions,
      additionalPrompt,
    });
  }, [
    socket,
    setIsAddingQuestion,
    quizId,
    editableQuestions,
    additionalPrompt,
  ]);

  const handleAddNewQuestion = () => {
    if (additionalPrompt && additionalPrompt.trim()) {
      requestAddQuestion();
      setAdditionalPrompt("");
    }
  };

  useEffect(() => {
    socket.on(
      "singleQuestionGenerationSuccess",
      ({ quizId: receivedQuizId, question, contentId, type }) => {
        setEditableQuestions((prevQuestions) => [
          ...prevQuestions,
          { ...question, contentId, type },
        ]);
        setIsAddingQuestion(false);
        if (!quizId) {
          setQuizId(receivedQuizId);
        }
      },
    );
    return () => {
      socket.off("singleQuestionGenerationSuccess");
    };
  }, [socket, quizId, setEditableQuestions, setIsAddingQuestion, setQuizId]);

  const handleGenerateTitle = useCallback(() => {
    setIsGeneratingTitle(true);
    socket.emit("generateTitle", { content: editableQuestions });
  }, [socket, editableQuestions]);

  useEffect(() => {
    if (editableQuestions.length === 1 && !title) {
      handleGenerateTitle();
    }
  }, [editableQuestions.length, title, handleGenerateTitle]);

  useEffect(() => {
    socket.on("titleGeneratedSuccess", ({ title }) => {
      setTitle(title);
      setIsGeneratingTitle(false);
    });

    socket.on("socketError", (message) => {
      console.error("Socket error:", message);
      setIsGeneratingTitle(false);
    });

    return () => {
      socket.off("titleGeneratedSuccess");
      socket.off("socketError");
    };
  }, [socket, setTitle, setIsGeneratingTitle]);

  const handleCreateQuizSession = useCallback(() => {
    if (!participantId) return;
    setIsStartingSession(true);
    socket.emit("createQuizSession", {
      quizId,
      participantId,
      questions: editableQuestions,
      title,
      topic: searchQuery,
    });
  }, [socket, quizId, participantId, editableQuestions, title, searchQuery]);

  const requestGeneratePrompt = async (searchQuery) => {
    setPrompts([]);
    setIsProcessing(true);
    try {
      socket.emit("generatePrompts", { topic: searchQuery });
    } catch (error) {
      console.error("Error occurred while generating prompts:", error);
      setIsProcessing(false);
    }
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleAnswerSelect = (questionIndex, selectedOption) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionIndex]: selectedOption,
    }));
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: correctAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleAddToQuiz = (suggestion) => {
    const newQuestion = {
      type: "mcq",
      question: suggestion.sampleMCQ.question,
      options: suggestion.sampleMCQ.options,
      correctAnswer: suggestion.sampleMCQ.correctAnswer,
      explanation: suggestion.sampleMCQ.explanation,
    };
    setEditableQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
  };

  // Editable Questions Handlers
  const handleQuestionChange = useCallback(
    (index, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[index].question = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleOptionChange = useCallback(
    (qIndex, oIndex, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[qIndex].options[oIndex] = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleCorrectAnswerChange = useCallback(
    (index, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[index].correctAnswer = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleExplanationChange = useCallback(
    (index, event) => {
      const newQuestions = [...editableQuestions];
      newQuestions[index].explanation = event.target.value;
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleDeleteQuestion = useCallback(
    (index) => {
      const newQuestions = editableQuestions.filter(
        (_, qIndex) => qIndex !== index,
      );
      setEditableQuestions(newQuestions);
    },
    [setEditableQuestions, editableQuestions],
  );

  const handleModeSwitch = () => {
    setEditMode(!editMode);
  };

  const moveArrayItem = (arr, fromIndex, toIndex) => {
    if (toIndex >= 0 && toIndex < arr.length) {
      const newArr = [...arr];
      const [movedItem] = newArr.splice(fromIndex, 1);
      newArr.splice(toIndex, 0, movedItem);
      return newArr;
    }
    return arr;
  };

  const handleMoveQuestionUp = (contentId) => {
    const index = editableQuestions.findIndex((q) => q.contentId === contentId);
    if (index > 0) {
      setEditableQuestions((prevQuestions) =>
        moveArrayItem(prevQuestions, index, index - 1),
      );
    }
  };

  const handleMoveQuestionDown = (contentId) => {
    const index = editableQuestions.findIndex((q) => q.contentId === contentId);
    if (index < editableQuestions.length - 1) {
      setEditableQuestions((prevQuestions) =>
        moveArrayItem(prevQuestions, index, index + 1),
      );
    }
  };

  useEffect(() => {
    const handlePromptsGeneratedSuccess = (data) => {
      setPrompts(data.prompts);
      setIsProcessing(false);
    };

    socket.on("promptsGeneratedSuccess", handlePromptsGeneratedSuccess);

    return () => {
      socket.off("promptsGeneratedSuccess", handlePromptsGeneratedSuccess);
    };
  }, [socket, setIsProcessing, setPrompts]);

  useEffect(() => {
    setPrompts(topics);
  }, [setPrompts]);

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  if (isStartingSession) {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Lottie options={defaultOptions} height={200} width={200} />
      </Box>
    );
  }

  return (
    <MathJaxContext>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh",
          overflow: "hidden",
          px: 2,
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        {/* Left Side - Main Content */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            overflowY: "hidden",
            zIndex: 1,
          }}
        >
          {/* Heading */}
          {editableQuestions.length === 0 && (
            <Typography variant="h4" gutterBottom>
              Create a <QuizSyncText>QuizSync</QuizSyncText>
            </Typography>
          )}

          <Box sx={{ width: "100%", maxWidth: "800px", mt: 2 }}>
            {/* Mode Switch, Start Session, and Quiz ID */}
            {editableQuestions.length > 0 && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={editMode}
                      onChange={handleModeSwitch}
                      color="primary"
                    />
                  }
                  label={editMode ? "Edit Mode" : "Render Mode"}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateQuizSession}
                  startIcon={<PlayArrowIcon />}
                >
                  Start Session
                </Button>
              </Box>
            )}

            {/* Title */}
            {editableQuestions.length > 0 && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                {editMode ? (
                  <TextField
                    label="Quiz Title"
                    value={title}
                    onChange={handleTitleChange}
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={handleGenerateTitle}
                          disabled={isGeneratingTitle}
                        >
                          {isGeneratingTitle ? (
                            <CircularProgress size={24} />
                          ) : (
                            <ShuffleIcon />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                ) : (
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      sx={{ textAlign: "center", mr: 1 }}
                    >
                      {title}
                    </Typography>
                    <IconButton
                      onClick={handleGenerateTitle}
                      disabled={isGeneratingTitle}
                    >
                      {isGeneratingTitle ? (
                        <CircularProgress size={24} />
                      ) : (
                        <ShuffleIcon />
                      )}
                    </IconButton>
                  </Box>
                )}
              </Box>
            )}

            {/* Editable Questions List */}
            {editMode ? (
              <List sx={{ width: "100%" }}>
                {editableQuestions.map((question, qIndex) => (
                  <Accordion
                    key={`question-${qIndex}`}
                    sx={{
                      marginBottom: 2,
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`question-${qIndex}-content`}
                      id={`question-${qIndex}-header`}
                    >
                      <Box display="flex" flexDirection="column" width="100%">
                        <Typography
                          variant="h6"
                          mb={1}
                        >{`Q${qIndex + 1}: ${question.question}`}</Typography>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box>
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                handleDeleteQuestion(qIndex);
                              }}
                              size="small"
                              color="error"
                            >
                              <DeleteIcon />
                            </IconButton>
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                handleMoveQuestionUp(question.contentId);
                              }}
                              size="small"
                              disabled={qIndex === 0}
                            >
                              <ArrowUpwardIcon />
                            </IconButton>
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                handleMoveQuestionDown(question.contentId);
                              }}
                              size="small"
                              disabled={qIndex === editableQuestions.length - 1}
                            >
                              <ArrowDownwardIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CardContent>
                        {/* Editable mode */}
                        <TextField
                          label={`Question ${qIndex + 1}`}
                          value={question.question}
                          onChange={(event) =>
                            handleQuestionChange(qIndex, event)
                          }
                          fullWidth
                          margin="normal"
                        />
                        {question.options.map((option, oIndex) => (
                          <TextField
                            key={`question-${qIndex}-option-${oIndex}`}
                            label={`Option ${oIndex + 1}`}
                            value={option}
                            onChange={(event) =>
                              handleOptionChange(qIndex, oIndex, event)
                            }
                            fullWidth
                            margin="normal"
                          />
                        ))}
                        <TextField
                          label="Correct Answer"
                          value={question.correctAnswer}
                          onChange={(event) =>
                            handleCorrectAnswerChange(qIndex, event)
                          }
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          label="Explanation"
                          value={question.explanation}
                          onChange={(event) =>
                            handleExplanationChange(qIndex, event)
                          }
                          fullWidth
                          margin="normal"
                        />
                      </CardContent>
                    </AccordionDetails>
                  </Accordion>
                ))}
                {isAddingQuestion && (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={80}
                    sx={{ marginBottom: 2 }}
                  />
                )}
              </List>
            ) : (
              // Render Mode
              <List sx={{ width: "100%" }}>
                {editableQuestions.map((question, qIndex) => {
                  const selectedAnswer = selectedAnswers[qIndex];
                  const isCorrect = selectedAnswer === question.correctAnswer;

                  return (
                    <motion.div
                      key={`render-question-${qIndex}`}
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{
                        duration: 0.5,
                        delay: qIndex * 0.1,
                      }}
                    >
                      <Card
                        variant="outlined"
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: 2,
                          gap: 2,
                          "&:hover": {
                            boxShadow: 6,
                            transform: "scale(1.02)",
                            transition: "transform 0.2s ease-in-out",
                          },
                        }}
                      >
                        <CardContent
                          sx={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                          }}
                        >
                          <Box sx={{ mb: 2 }}>
                            <Typography
                              variant="h6"
                              sx={{ textAlign: "center", marginBottom: 2 }}
                            >
                              {`Q${qIndex + 1}: ${question.question}`}
                            </Typography>

                            {/* Render the options */}
                            {question.options.map((option, oIndex) => {
                              const isSelected = selectedAnswer === option;
                              const showCorrect =
                                selectedAnswer &&
                                option === question.correctAnswer;
                              const showIncorrect = isSelected && !isCorrect;

                              return (
                                <Typography
                                  key={`render-option-${qIndex}-${oIndex}`}
                                  sx={{
                                    border: 2,
                                    borderColor: showCorrect
                                      ? "success.main"
                                      : showIncorrect
                                        ? "error.main"
                                        : "inherit",
                                    color: showCorrect
                                      ? "success.main"
                                      : showIncorrect
                                        ? "error.main"
                                        : "inherit",
                                    borderWidth: 2,
                                    padding: "8px 16px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    borderRadius: 1,
                                    transition: "background-color 0.2s ease",
                                    "&:hover": {
                                      backgroundColor: showCorrect
                                        ? "success.light"
                                        : showIncorrect
                                          ? "error.light"
                                          : "action.hover",
                                    },
                                    mb: 1,
                                  }}
                                  onClick={() =>
                                    handleAnswerSelect(qIndex, option)
                                  }
                                >
                                  {option}
                                  {showCorrect && <CheckIcon />}
                                </Typography>
                              );
                            })}
                          </Box>

                          {/* Show explanation and correct answer */}
                          {selectedAnswer && (
                            <Box>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{ mt: 1 }}
                              >
                                Correct Answer:{" "}
                                <strong>{question.correctAnswer}</strong>
                              </Typography>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{ mt: 1 }}
                              >
                                Explanation:{" "}
                                <strong>{question.explanation}</strong>
                              </Typography>
                            </Box>
                          )}
                        </CardContent>
                      </Card>
                    </motion.div>
                  );
                })}
              </List>
            )}

            {/* Generate MCQ Component */}
            <GenerateMCQ
              additionalPrompt={additionalPrompt}
              setAdditionalPrompt={setAdditionalPrompt}
              handleAddNewQuestion={handleAddNewQuestion}
            />
          </Box>
        </Box>

        {/* Right Sidebar Component */}
        {!isMobile && (
          <>
            <Box
              sx={{
                position: "fixed",
                top: "50%",
                right: 0,
                transform: "translateY(-50%)",
                zIndex: 1000,
              }}
            >
              <DragIndicatorIcon onClick={toggleDrawer(true)} />
            </Box>
            <RightSidebar
              isDrawerOpen={isDrawerOpen}
              toggleDrawer={toggleDrawer}
              isProcessing={isProcessing}
              searchQuery={searchQuery}
              handleSearchQueryChange={handleSearchQueryChange}
              requestGeneratePrompt={requestGeneratePrompt}
              prompts={prompts}
              selectedTopic={selectedTopic}
              setSelectedTopic={setSelectedTopic}
              selectedAnswers={selectedAnswers}
              handleAnswerSelect={handleAnswerSelect}
              handleDeeperPromptGeneration={handleDeeperPromptGeneration}
              handleAddToQuiz={handleAddToQuiz}
              defaultOptions={defaultOptions}
            />
          </>
        )}
      </Box>
    </MathJaxContext>
  );
};

export default Create;
