import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  CssBaseline,
  ThemeProvider,
  createTheme,
  Typography,
} from "@mui/material";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import io from "socket.io-client";
import LoadingScreen from "./components/LoadingScreen";
import IdleScreen from "./components/IdleScreen";
import AppBar from "./components/AppBar";
import Drawer from "./components/Drawer";
import HostView from "./components/HostView";
import ParticipantView from "./components/ParticipantView";
import FinalResults from "./components/FinalResults";
import LoadingQuestions from "./components/LoadingQuestions";
import ReviewQuestions from "./components/ReviewQuestions";
import Login from "./components/Login";
import Create from "./components/Create";
import LandingPage from "./components/LandingPage";
import SessionDetails from "./components/SessionDetails";
import Join from "./components/Join";

import useQuizSessionManager from "./components/QuizSessionManager";

const socket = io(
  "https://c8e788ef-e1df-4ae7-b024-181fa88bf8d2-00-kw6mo9fimmcq.sisko.replit.dev/",
);

//const socket = io("https://server-interactive-learning-one.replit.app/");

const App = () => {
  // Common state
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [participantId, setParticipantId] = useState(null);
  const [avatar, setAvatar] = useState("");
  const [mode, setMode] = useState("join");
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem("themeMode") || "light",
  );
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [isIdle, setIsIdle] = useState(false);
  const [userTriggeredActivity, setUserTriggeredActivity] = useState(false);
  const [activeQuizSession, setActiveQuizSession] = useState(null);
  const [isHost, setIsHost] = useState(false);
  //const [showLandingPage, setShowLandingPage] = useState(false);

  // State for Create Component
  const [isProcessing, setIsProcessing] = useState(false);
  const [prompts, setPrompts] = useState([]);

  // State for ReviewQuestions Component
  const [editableQuestions, setEditableQuestions] = useState([]);
  const [quizId, setQuizId] = useState("");
  const [loadedQuizId, setLoadedQuizId] = useState("");
  const [additionalPrompt, setAdditionalPrompt] = useState("");
  const [isAddingQuestion, setIsAddingQuestion] = useState(false);

  // State for Host/Participant Views
  const [quizCode, setQuizCode] = useState("");
  const [title, setTitle] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [responses, setResponses] = useState(0);
  const [results, setResults] = useState({});
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [explanation, setExplanation] = useState("");
  const [answer, setAnswer] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [scores, setScores] = useState({});
  const [scoreGrid, setScoreGrid] = useState({});

  // State for FinalResults Component
  const [quizResults, setQuizResults] = useState(null);

  // State for Quiz List/Drawer
  const [quizzesList, setQuizzesList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredQuizzes, setFilteredQuizzes] = useState([]);

  const [selectedSession, setSelectedSession] = useState(null);
  const [loadingSession, setLoadingSession] = useState(false);

  const {
    initiateQuizJoin,
    submitAnswerToServer,
    reloadQuizSession,
    handleNextQuestion,
  } = useQuizSessionManager({
    socket,
    userInfo,
    setMode,
    setActiveQuizSession,
    setQuizCode,
    setTitle,
    setCurrentQuestion,
    setCurrentQuestionIndex,
    setTotalQuestions,
    setParticipants,
    setIsHost,
    quizCode,
    title,
    isHost,
    setTotalParticipants,
    setResponses,
    setResults,
    setCorrectAnswer,
    setExplanation,
    setAnswer,
    setHasSubmitted,
    setShowAnswers,
    setParticipantId,
    setScores,
    setAvatar,
    setEditableQuestions,
    setQuizId,
    setLoadedQuizId,
    setScoreGrid,
    participantId,
    answer,
    hasSubmitted,
    activeQuizSession,
    setQuizResults,
    loadedQuizId,
    quizId,
  });

  useEffect(() => {
    socket.on("disconnect", () => {
      setIsIdle(true);
    });
    return () => {
      socket.off("disconnect");
    };
  }, []);

  // THEME
  const theme = createTheme({
    palette: {
      mode: themeMode,
    },
  });

  const toggleThemeMode = () => {
    const newMode = themeMode === "light" ? "dark" : "light";
    setThemeMode(newMode);
    localStorage.setItem("themeMode", newMode);
  };

  // LOGIN AND RECONNECTION
  const idleTimeout = 5400000; // 90 minute idle time
  const idleTimer = useRef(null);

  const resetIdleTimer = useCallback(() => {
    if (isIdle && !userTriggeredActivity) return;

    setIsIdle(false);
    clearTimeout(idleTimer.current);
    idleTimer.current = setTimeout(() => {
      setIsIdle(true);
      setUserTriggeredActivity(false);
      socket.disconnect();
    }, idleTimeout);
  }, [idleTimeout, isIdle, userTriggeredActivity]);

  useEffect(() => {
    window.addEventListener("mousemove", resetIdleTimer);
    window.addEventListener("keydown", resetIdleTimer);
    window.addEventListener("scroll", resetIdleTimer);
    window.addEventListener("touchstart", resetIdleTimer);

    resetIdleTimer();

    return () => {
      window.removeEventListener("mousemove", resetIdleTimer);
      window.removeEventListener("keydown", resetIdleTimer);
      window.removeEventListener("scroll", resetIdleTimer);
      window.removeEventListener("touchstart", resetIdleTimer);
      clearTimeout(idleTimer.current);
    };
  }, [resetIdleTimer]);

  const handleReconnect = () => {
    setIsIdle(false);
    setUserTriggeredActivity(true);
    resetIdleTimer();
    socket.connect();
    validateToken();
  };

  const validateToken = useCallback(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      socket.emit("validateToken", { token });
    } else {
      setIsLoggedIn(false);
      setIsCheckingAuth(false);
    }
  }, []);

  const onLoginSuccess = (user) => {
    setUserInfo(user);
    validateToken();
    setIsCheckingAuth(true);
  };

  useEffect(() => {
    socket.on(
      "userTokenValidationResult",
      ({ isValid, user, participantId, avatar }) => {
        if (isValid) {
          setUserInfo(user);
          //console.log(user.role);
          setParticipantId(participantId);
          setAvatar(avatar);

          if (participantId) {
            setIsLoggedIn(true);
            socket.emit("getQuizzes");
          }
        } else {
          localStorage.removeItem("authToken");
          setIsLoggedIn(false);
        }
        setIsCheckingAuth(false);
      },
    );
    return () => {
      socket.off("userTokenValidationResult");
    };
  }, []);

  //REVIEW
  const onServerQuestionsGenerated = useCallback(
    ({ questions, title, quizId, content }) => {
      setTitle(title);
      setQuizId(quizId);
      setEditableQuestions(
        questions.map((q, index) => ({
          ...q,
          id: index,
          contentId: content[index]?.id,
          type: content[index]?.type,
        })),
      );
      setAdditionalPrompt(searchQuery);
      setMode("reviewQuestions");
    },
    [searchQuery],
  );

  //Drawer
  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(isOpen);
    if (isOpen) {
      socket.emit("getQuizzes");
    }
  };

  //Other
  const onServerError = useCallback((message) => {
    if (message) {
      alert(message);
    }
    setMode("join");
    setActiveQuizSession(null); // Reset the active quiz session
  }, []);

  // Initial Effects
  useEffect(() => {
    validateToken();
  }, [validateToken]);

  // Effects for Create Component?
  useEffect(() => {
    socket.on("questionsGeneratedSuccess", onServerQuestionsGenerated);

    return () => {
      socket.off("questionsGeneratedSuccess", onServerQuestionsGenerated);
    };
  }, [onServerQuestionsGenerated]);

  //other
  useEffect(() => {
    socket.on("socketError", onServerError);

    return () => {
      socket.off("socketError", onServerError);
    };
  }, [onServerError]);

  useEffect(() => {
    socket.on("logout", (message) => {
      alert(message || "You have been logged out.");

      // Optionally clear state or handle any other logout cleanup
      document.body.innerHTML = "<h1>You have been logged out.....</h1>";
    });

    return () => {
      socket.off("logout");
    };
  }, []);

  useEffect(() => {
    const handleUnload = () => {
      socket.emit("disconnect");
    };

    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isHost) {
        const message =
          "Closing this window will end the quiz for all participants.";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isHost]);

  useEffect(() => {
    socket.on("sessionDetails", (sessionDetails) => {
      setSelectedSession(sessionDetails);
      setLoadingSession(false);
    });
    return () => {
      socket.off("sessionDetails");
    };
  }, []);

  if (isCheckingAuth) {
    return <LoadingScreen />;
  }

  if (!isLoggedIn) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/login"
            element={<Login onLoginSuccess={onLoginSuccess} />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    );
  }

  if (isIdle) {
    return <IdleScreen onReconnect={handleReconnect} />;
  }

  const renderContent = () => {
    switch (mode) {
      case "choose":
        return userInfo?.role === "Teacher" ? (
          <Create
            socket={socket}
            isProcessing={isProcessing}
            setIsProcessing={setIsProcessing}
            prompts={prompts}
            setPrompts={setPrompts}
            setMode={setMode}
            participantId={participantId}
            additionalPrompt={additionalPrompt}
            setAdditionalPrompt={setAdditionalPrompt}
            isAddingQuestion={isAddingQuestion}
            setIsAddingQuestion={setIsAddingQuestion}
            editableQuestions={editableQuestions}
            setEditableQuestions={setEditableQuestions}
            quizId={quizId}
            setQuizId={setQuizId}
            title={title}
            setTitle={setTitle}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              textAlign: "center",
            }}
          >
            <Typography variant="h6" color="error">
              You do not have permission to create quizzes.
            </Typography>
          </Box>
        );
      case "join":
        return (
          <Join
            initiateQuizJoin={initiateQuizJoin}
            quizCode={quizCode}
            setQuizCode={setQuizCode}
          />
        );
      case "loadingQuestions":
        return <LoadingQuestions />;
      case "reviewQuestions":
        return (
          <ReviewQuestions
            socket={socket}
            participantId={participantId}
            searchQuery={searchQuery}
            setIsAddingQuestion={setIsAddingQuestion}
            editableQuestions={editableQuestions}
            isAddingQuestion={isAddingQuestion}
            additionalPrompt={additionalPrompt}
            setAdditionalPrompt={setAdditionalPrompt}
            quizId={quizId}
            title={title}
            loadedQuizId={loadedQuizId}
            setEditableQuestions={setEditableQuestions}
          />
        );
      case "hostActive":
        return (
          <HostView
            title={title}
            quizCode={quizCode}
            totalParticipants={totalParticipants}
            currentQuestion={currentQuestion}
            currentQuestionIndex={currentQuestionIndex}
            totalQuestions={totalQuestions}
            results={results}
            answer={answer}
            hasSubmitted={hasSubmitted}
            correctAnswer={correctAnswer}
            explanation={explanation}
            showAnswers={showAnswers}
            responses={responses}
            participants={participants}
            scores={scores}
            isHost={isHost}
            handleNextQuestion={handleNextQuestion}
            handleSubmitAnswer={submitAnswerToServer}
            setAnswer={setAnswer}
            participantId={participantId}
            scoreGrid={scoreGrid}
          />
        );
      case "participantActive":
        return (
          <ParticipantView
            currentQuestion={currentQuestion}
            currentQuestionIndex={currentQuestionIndex}
            totalQuestions={totalQuestions}
            totalParticipants={totalParticipants}
            title={title}
            answer={answer}
            setAnswer={setAnswer}
            handleSubmitAnswer={submitAnswerToServer}
            hasSubmitted={hasSubmitted}
            showAnswers={showAnswers}
            correctAnswer={correctAnswer}
            explanation={explanation}
            avatar={avatar}
          />
        );
      case "results":
        return (
          <FinalResults
            finalResults={quizResults?.finalResults}
            participantAnswers={quizResults?.participantAnswers}
            scores={quizResults?.scores}
            totalParticipants={totalParticipants}
            participantId={participantId}
            isHost={isHost}
            title={quizResults.title}
            quizId={quizResults.quizId}
            code={quizResults.code}
          />
        );

      case "sessionDetails":
        return (
          <SessionDetails
            loadingSession={loadingSession}
            selectedSession={selectedSession}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: "flex" }}>
        {/* AppBar */}
        <AppBar
          socket={socket}
          activeQuizSession={activeQuizSession}
          userInfo={userInfo}
          toggleDrawer={toggleDrawer}
          setMode={setMode}
          reloadQuizSession={reloadQuizSession}
          avatar={avatar}
          isLoggedIn={isLoggedIn}
          setActiveQuizSession={setActiveQuizSession}
          quizCode={quizCode}
          mode={mode}
          anchorEl={anchorEl}
          themeMode={themeMode}
          toggleThemeMode={toggleThemeMode}
          setUserInfo={setUserInfo}
          setAnchorEl={setAnchorEl}
          setParticipantId={setParticipantId}
          setIsLoggedIn={setIsLoggedIn}
        />
        {userInfo?.role === "Teacher" && (
          <Drawer
            socket={socket}
            drawerOpen={drawerOpen}
            toggleDrawer={toggleDrawer}
            searchQuery={searchQuery}
            filteredQuizzes={filteredQuizzes}
            loadedQuizId={loadedQuizId}
            quizId={quizId}
            setLoadedQuizId={setLoadedQuizId}
            setSearchQuery={setSearchQuery}
            setFilteredQuizzes={setFilteredQuizzes}
            quizzesList={quizzesList}
            setQuizId={setQuizId}
            setTitle={setTitle}
            setMode={setMode}
            setEditableQuestions={setEditableQuestions}
            setQuizzesList={setQuizzesList}
            participantId={participantId}
            setSelectedSession={setSelectedSession}
          />
        )}

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            mt: 8,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {renderContent()}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
export default App;
